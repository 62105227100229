@font-face {
  font-family: 'playfair_displayregular';
  src: url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair_displaybold';
  src: url('../fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair_displayblack';
  src: url('../fonts/playfairdisplay-black-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair_displayblack_italic';
  src: url('../fonts/playfairdisplay-blackitalic-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair_displaybold_italic';
  src: url('../fonts/playfairdisplay-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair_displayitalic';
  src: url('../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
       url('../fonts/playfairdisplay-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT';
  src: url('../fonts/Trade Gothic LT.woff2') format('woff2'),
       url('../fonts/Trade Gothic LT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Bold No. 2';
  src: url('../fonts/Trade Gothic LT Bold No. 2.woff2') format('woff2'),
       url('../fonts/Trade Gothic LT Bold No. 2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

